// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("/opt/build/repo/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-add-smoke-session-js": () => import("/opt/build/repo/src/pages/add-smoke-session.js" /* webpackChunkName: "component---src-pages-add-smoke-session-js" */),
  "component---src-pages-home-js": () => import("/opt/build/repo/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pw-forget-js": () => import("/opt/build/repo/src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-signin-js": () => import("/opt/build/repo/src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("/opt/build/repo/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

